import { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SpaceStickyFooter from '../SpaceStickyFooter';
import CheckoutHeader from '../../pages/Checkout/CheckoutHeader';

import PaymentSummary from '../PaymentSummary';

import './styles.scss';
import useReduxKey from '../../hooks/useReduxKey';
import { PriceContext } from '../../contexts/PriceProvider';
import { Configuration } from '../../reducers/configuration';

export default function PaymentOverview({ handlePayment }): ReactElement {
  const { t } = useTranslation();
  const { getPrice } = useContext(PriceContext);

  const { showPayments, paymentsEnabled } = useReduxKey<Configuration>('configuration');

  useEffect(() => {
    if (paymentsEnabled && !showPayments) {
      getPrice();
    }
  }, [showPayments, paymentsEnabled]);

  return (
    <>
      <CheckoutHeader step={5} />
      <div className="container-checkout-overview">
        <PaymentSummary />
      </div>

      <SpaceStickyFooter label={t('pay')} actionCallback={handlePayment} />
    </>
  );
}
