import Pusher from 'pusher-js';
import ReservationConfirmation from '../ReservationConfirmation/index';
import ProcessPending from '../ProcessPending/index';
import PaymentOverview from '../PaymentOverview/index';
import Error from '../Error/index';

import './styles.scss';
import usePayment from '../../hooks/usePayment';

export default function PaymentProcess({ reservation }): JSX.Element {
  const { loading, success, error, finished, reset, startPayment, flowStatus } = usePayment(reservation.id);

  return !loading
    ? (
      <>
        {
          finished
            ? (
              <>
                { success && <ReservationConfirmation /> }
                { error && <Error processName="payment" actionCallback={reset} /> }
              </>
            )
            : (
              <PaymentOverview handlePayment={startPayment} />
            )
        }
      </>
    )
    : (
      <ProcessPending
        processName={flowStatus === 'opened' ? 'payment' : 'booking'}
      />
    );
}
