import { useTranslation } from 'react-i18next';

import './CheckoutHeader.scss';
import { useNavigate } from 'react-router-dom';
import useReduxKey from '../../hooks/useReduxKey';

const CheckoutHeader = ({ step }: { step: number|string }): JSX.Element => {
  const { t } = useTranslation();

  const { showNotes, contactId, leadId, accountId, showTermsStep, paymentsEnabled, usePerAttendeeFields, showSelectedResourceStep } = useReduxKey('configuration');
  const navigate = useNavigate();

  const steps = [
    <div key="overview" className="individual-step">
      <span className="dot" />
      {t('overview')}
    </div>,
    ...(
      showNotes
        ? [(
          <div key="notes" className="individual-step">
            <span className="dot" />
            {t('notes')}
          </div>
        )]
        : []
    ),
    ...(
      contactId || leadId || accountId
        ? []
        : [(
          <div key="details" className="individual-step">
            <span className="dot" />
            {t('details')}
          </div>
        )]
    ),
    ...(
      usePerAttendeeFields
        ? [(
          <div key="attendees" className="individual-step">
            <span className="dot" />
            {t('attendees')}
          </div>
        )]
        : []
    ),
    ...(
      showTermsStep
        ? [(
          <div key="terms" className="individual-step">
            <span className="dot" />
            {t('terms_conditions')}
          </div>
        )] : []
    ),
    ...(
      paymentsEnabled
        ? [(
          <div key="payment" className="individual-step">
            <span className="dot" />
            {t('payment')}
          </div>
        )] : []
    ),
  ];

  function onClickBack() {
	let navigationSteps = -1;
	if (!showSelectedResourceStep && step === "1") {
		navigationSteps = -3; // nav back to initial selection page if we are skipping selected resources
	}

	navigate(navigationSteps);
  }

  return (
    <div className="checkout-header">
      <div className="back-and-header">
        <button type="button" className="back-button" onClick={onClickBack}>Back</button>
        <h2>{t('checkout')}</h2>
      </div>
      <div className={['progress-stepper', `step-${step}`,
        `total-steps-${steps.length}`].join(' ')}
      >
        { steps }
      </div>
    </div>
  );
};

export default CheckoutHeader;
