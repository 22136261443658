import { useEffect, useMemo, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CheckoutHeader from './CheckoutHeader';
import SpaceStickyFooter from '../../components/SpaceStickyFooter';

import ReservationCard from '../../components/ReservationCard';
import { TrackingEventEnum } from '../../utils/analytics';
import useAnalytics from '../../hooks/useAnalytics';
import useReduxKey from '../../hooks/useReduxKey';

import './Overview.scss';
import { FilterState } from '../../reducers/filter';

export default function OverviewPage(): ReactElement {
  const navigate = useNavigate();
  const {
    reservationType, selectedTimeSlot,
    groupSize, selectedSpace,
  } = useReduxKey<FilterState>('filterState');
  const { t } = useTranslation();

  const { userReservationTypes } = useReduxKey('configuration');
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(TrackingEventEnum.SCHEDULE_OVERVIEW);
  }, [trackEvent]);

  const selectedReservationTypeName = useMemo(() => {
    const userType = userReservationTypes.find((type) => 
      type.reservation_type === reservationType);
    return userType?.name;
  }, [userReservationTypes, reservationType]);

  if (selectedSpace === null) {
    return <span>{t('coming_up')}</span>;
  }

  return (
    <>
      <CheckoutHeader step="1" />

      <div className="container-checkout-overview">
        <ReservationCard
          space={selectedSpace}
          reservation={{ quantity: groupSize }}
          selectedTimeSlot={selectedTimeSlot}
          reservationType={selectedReservationTypeName}
        />
      </div>

      <SpaceStickyFooter navigate={navigate} next="/notes" nextStep={2} />
    </>
  );
}