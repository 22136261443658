import { useCallback, useEffect, useReducer, useRef } from 'react';
import Pusher from 'pusher-js';
import { finished } from 'stream';
import useBooker25 from './useBooker25';
import { Payment, PaymentStatus } from '../services/Booker25';

type PaymentFlowStatus = 'notinitialized' | 'initialized' | 'opened' | 'returned'
interface UsePaymentData {
  loading: boolean,
  reference?: string | null
  url?: string | null
  flowStatus: PaymentFlowStatus
  finished: boolean
  error: boolean
  success: boolean
}

interface SetAction {
  type: 'set'
  payload: {
    key: keyof UsePaymentData
    value: any
  }
}

interface ResetAction {
  type: 'reset'
  payload?: undefined
}

const INITIAL_STATE: UsePaymentData = {
  loading: true,
  flowStatus: 'notinitialized',
  finished: false,
  error: false,
  success: false,
};

type Action = SetAction | ResetAction;
function usePaymentReducer(state: UsePaymentData, { type, payload = undefined }: Action): UsePaymentData {
  switch (type) {
    case 'set':
      const { key, value } = payload;
      return {
        ...state,
        [key]: value,
      };
    case 'reset':
      return INITIAL_STATE;
    default:
      return state;
  }
}

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: 'eu',
});

interface UsePayment extends UsePaymentData {
  startPayment: () => void,
  reset: () => Promise<void>,
}

export default function usePayment(reservationId: string): UsePayment {
  const [state, dispatch] = useReducer(usePaymentReducer, INITIAL_STATE);
  const paymentWindow = useRef<Window | null>(null);
  const set = useCallback(<T = any>(key: keyof UsePaymentData, value: T) => dispatch({
    type: 'set',
    payload: { key, value },
  }), []);

  const reset = useCallback(() => dispatch({
    type: 'reset',
  }), []);

  const booker = useBooker25();
  const initiatePayment = useCallback(async () => {
    const userLocale = navigator.language;

    try {
      const { reference, url } = await booker.initiatePayment({
        reservation_id: reservationId,
        user_locale: userLocale,
      });

      set('reference', reference);
      set('url', url);

      if (reference === null) {
        // No payment reference since the reservation was free
        set('success', true);
        set('finished', true);
      } else {
        set<PaymentFlowStatus>('flowStatus', 'initialized');
      }
    } catch (e) {
      set('finished', true);
      set('error', true);
    } finally {
      set('loading', false);
    }
  }, [reservationId, booker]);

  const setFlowStatus = useCallback(async ({ status }: Payment): Promise<void> => {
    switch (status) {
      case PaymentStatus.Pending:
        set('loading', true);
        set('finished', false);
        break;
      case PaymentStatus.Authorized:
      case PaymentStatus.Paid:
        set('success', true);
        set('loading', false);
        set('finished', true);
        break;
      case PaymentStatus.Failed:
      case PaymentStatus.Aborted:
        set('error', true);
        set('loading', false);
        set('finished', true);
        break;
      default:
    }
  }, []);

  useEffect(() => {
    if (!state.finished) {
      const channel = pusher.subscribe(reservationId);

      channel.bind('status-updated', (payment: Payment) => {
        setFlowStatus(payment);
      });

      channel.bind('updated', (payment: Payment) => {
        set<PaymentFlowStatus>('flowStatus', 'returned');
      });

      return () => pusher.unsubscribe(reservationId);
    }

    return () => undefined;
  }, [state.finished]);

  useEffect(() => {
    initiatePayment().then(() => { /* pass */ });
  }, [reservationId]);

  useEffect(() => {
    if (state.flowStatus === 'returned' || state.finished) {
      if (paymentWindow.current !== null) {
        try {
          paymentWindow.current.close();
        } finally {
          paymentWindow.current = null;
        }
      }
    }
  }, [state.flowStatus, state.finished]);

  return {
    ...state,
    startPayment: () => {
      if (state.reference === null) {
        set('finished', true);
      } else if (paymentWindow.current === null) {
        paymentWindow.current = window.open(state.url);
        set<PaymentFlowStatus>('flowStatus', 'opened');
        set('loading', true);
      }
    },
    reset: async () => {
      reset();
      await initiatePayment();
    },
  };
}
