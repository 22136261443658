import { createContext, useCallback, useEffect, useState } from 'react';
import useReduxKey from '../hooks/useReduxKey';
import { PriceBreakdown, ServicePrototype} from '../types';
import useBooker25 from '../hooks/useBooker25';
import { FilterState } from '../reducers/filter';
import { Services } from '../reducers/services';
import { Configuration } from '../reducers/configuration';

interface ServicesTotals {
  servicesSubtotal?: number
  servicesSubtotalIncl?: number
}

interface PriceContextApi extends Partial<PriceBreakdown & ServicesTotals> {
  getPrice: (abortSignal?: AbortSignal) => Promise<void>
}

export const PriceContext = createContext<Partial<PriceContextApi>>({});

export default function PriceProvider({ children }): JSX.Element {
  const {
    reservationType,
    selectedSpace,
    groupSize,
    selectedTimeSlot,
  } = useReduxKey<FilterState>('filterState');
  const {
    includedServices,
    additionalServices,
  } = useReduxKey<Services>('services');

  const {
    showPayments,
	accountId
  } = useReduxKey('configuration');

  const booker25 = useBooker25();

  const [priceBreakdown, setPriceBreakdown] = useState<Partial<PriceBreakdown>>({});
  const [servicesTotals, setServicesTotals] = useState<Partial<ServicesTotals>>({});

  const getPrice = useCallback((abortSignal?: AbortSignal) => (
    booker25
      .getPriceBreakdown({
        resource_id: selectedSpace.id,
        start_date: selectedTimeSlot.dStart.toISOString(),
        end_date: selectedTimeSlot.dEnd.toISOString(),
        reservation_type: reservationType,
        quantity: groupSize,
        services: [...includedServices ?? [], ...additionalServices ?? []]
          .filter(({ selectedQuantity }) => selectedQuantity > 0)
          .map<ServicePrototype>(({
            id,
            selectedQuantity,
          }) => ({
            id,
            quantity: selectedQuantity,
          })),
        account_id: accountId,
      }, abortSignal)
      .then(setPriceBreakdown)
      .catch((e) => {
        if (!(e instanceof DOMException && e.name === 'AbortError')) {
          throw e;
        }
      })
  ), [
    reservationType,
    selectedTimeSlot,
    selectedSpace,
    groupSize,
    includedServices,
    additionalServices,
    showPayments
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (selectedTimeSlot !== null && selectedSpace !== null && showPayments) {
      getPrice(abortController.signal);
    }

    return () => {
      abortController.abort();
    };
  }, [
    reservationType,
    selectedTimeSlot,
    selectedSpace,
    groupSize,
    includedServices,
    additionalServices,
    showPayments
  ]);

  useEffect(() => {
    priceBreakdown.services !== undefined && setServicesTotals({
      servicesSubtotal: priceBreakdown.services
        .reduce((total, { subtotal }) => (
          total + subtotal
        ), 0),
      servicesSubtotalIncl: priceBreakdown.services
        .reduce((total, { subtotal_incl: subtotalIncl}) => (
          total + subtotalIncl
        ), 0),
    })
}, [priceBreakdown]);

  return (
    <PriceContext.Provider
      value={{
        ...priceBreakdown,
        ...servicesTotals,
        getPrice,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
}
